/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import ReviewContent from 'shared/page-content/ReviewContent';

import { getApplicationPhase } from 'shared/general/Util';
import op from 'object-path';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * React Component: Review
 * -----------------------------------------------------------------------------
 */
export default class Review extends Component {
	constructor(props) {
		super(props);

		this.applicationDataLoaded = false;
		this.tableHighlight = '';
		logger.log('[Review] - constructor this%o', this);
	}

	componentDidMount() {
		logger.log('[Review] - componentDidMount this%o', this);
		this.props.loadConfig();
	}

	componentDidUpdate(prevProps) {
		/** if this is the application review once the application data has updated
		 * in props.userData.application and if it's different than the prevProps.userData.application
		 * data, then create the data objects for TicketApplication component
		 */
		if (
			op.get(this.props, 'userData.application', false) &&
			op.get(this.props, 'eventDays', false) &&
			!isEmpty(op.get(this.props, 'eventConfig')) &&
			!isEqual(prevProps.userData, this.props.userData)
		) {
			this.applicationDataLoaded = true;
			let practiceRoundData = this.createApplication(false);
			let dailyTournamentData = this.createApplication(true);

			this.setState({
				practiceRound: practiceRoundData.length > 0 ? practiceRoundData : null,
				dailyTournament: dailyTournamentData.length > 0 ? dailyTournamentData : null,
			});
		}

		if (
			op.get(this.props, 'userData.application', false) &&
			op.get(this.props, 'eventDays', false) &&
			!isEmpty(op.get(this.props, 'eventConfig')) &&
			(op.get(this.props, 'match.params.category', false) == 'application' ||
				op.get(this.props, 'match.params.category', false) == 'payment') &&
			!this.applicationDataLoaded
		) {
			this.applicationDataLoaded = true;
			let practiceRoundData = this.createApplication(false);
			let dailyTournamentData = this.createApplication(true);

			this.setState({
				practiceRound: practiceRoundData.length > 0 ? practiceRoundData : null,
				dailyTournament: dailyTournamentData.length > 0 ? dailyTournamentData : null,
			});
		}
	}

	createApplication(isTourn) {
		let ticketApplication = [];

		let { eventDays } = this.props;
		let { application = {} } = this.props.userData;
		let days = Object.keys(this.props.applicationConfig.days);

		let category = op.get(this.props, 'match.params.category', false);
		let action = op.get(this.props, 'match.params.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		days.map(day => {
			if (this.props.applicationConfig.days[day].isTourn == isTourn) {
				logger.log('days are' + JSON.stringify(day) + op.get(application, `${day}Approved`, 0));
				if (category == 'payment' && action == 'review') {
					if (op.get(application, `${day}Approved`, 0) > 0) {
						ticketApplication.push({
							ROUND: `${this.props.messageSettings?.[`${day}_date_day_long`]}<br />
									${eventDays[day].day}<br />At ${eventDays[day].location}<br /><br />
									$${this.props.eventConfig[`${day}Price`].toFixed(2)} each`,
							'TICKETS REQUESTED': op.get(application, `${day}Requested`, 0),
							'TICKETS APPROVED': op.get(application, `${day}Approved`, 0),
						});
						this.tableHighlight = 'TICKETS APPROVED';
					}
				} else if (
					(category == 'payment' && action == 'complete' && applicationPhase == 'applicationPaid') ||
					(category == 'application' && action == 'review' && applicationPhase == 'applicationPaid')
				) {
					if (op.get(application, `${day}Approved`, 0) > 0) {
						ticketApplication.push({
							ROUND: `${this.props.messageSettings?.[`${day}_date_day_long`]}<br />
									${eventDays[day].day}<br />At ${eventDays[day].location}`,
							'TICKETS REQUESTED': op.get(application, `${day}Requested`, 0),
							'TICKETS APPROVED': op.get(application, `${day}Approved`, 0),
							'TICKETS PURCHASED': `${op.get(
								application,
								`${day}Approved`,
								0
							)} x $${this.props.eventConfig[`${day}Price`].toFixed(2)}`,
						});
						this.tableHighlight = 'TICKETS PURCHASED';
					}
				} else if (category == 'application' && action == 'review' && applicationPhase == 'applicationWinner') {
					ticketApplication.push({
						ROUND: `${this.props.messageSettings?.[`${day}_date_day_long`]}<br />
								${eventDays[day].day}<br />At ${eventDays[day].location}<br /><br />
								$${this.props.eventConfig[`${day}Price`].toFixed(2)} each`,
						'TICKETS REQUESTED': op.get(application, `${day}Requested`, 0),
						'TICKETS APPROVED': op.get(application, `${day}Approved`, 0),
						'TICKETS PURCHASED': 0,
					});
					this.tableHighlight = 'TICKETS APPROVED';
				} else if (category == 'application' && action == 'review' && applicationPhase == 'applicationLoser') {
					ticketApplication.push({
						ROUND: `${this.props.messageSettings?.[`${day}_date_day_long`]}<br />
								${eventDays[day].day}<br />At ${eventDays[day].location}`,
						'TICKETS REQUESTED': op.get(application, `${day}Requested`, 0),
						'TICKETS APPROVED': op.get(application, `${day}Approved`, 0),
					});
					this.tableHighlight = 'TICKETS REQUESTED';
				} else if (
					category == 'application' &&
					action == 'review' &&
					(applicationPhase == 'applicationSubmittedEdit' || applicationPhase == 'applicationSubmittedNoEdit')
				) {
					ticketApplication.push({
						ROUND: `${this.props.messageSettings?.[`${day}_date_day_long`]}<br />
								${eventDays[day].day}<br />At ${eventDays[day].location}<br /><br />
								$${this.props.eventConfig[`${day}Price`].toFixed(2)} each`,
						'TICKETS REQUESTED': op.get(application, `${day}Requested`, 0),
						'TICKETS APPROVED': '',
					});
					this.tableHighlight = 'TICKETS REQUESTED';
				} else if (category == 'application' && action == 'review') {
					ticketApplication.push({
						ROUND: `${this.props.messageSettings?.[`${day}_date_day_long`]}<br />
								${eventDays[day].day}<br />At ${eventDays[day].location}<br /><br />
								$${this.props.eventConfig[`${day}Price`].toFixed(2)} each`,
						'TICKETS REQUESTED': op.get(application, `${day}Requested`, 0),
						'TICKETS APPROVED': '',
					});
					this.tableHighlight = 'TICKETS REQUESTED';
				}
			}
		});

		logger.log('[Review] createApplication ticketApplication:%o', ticketApplication);
		return ticketApplication;
	}

	render() {
		logger.log('[Review] render - this:%o', this);

		let category = op.get(this.props, 'match.params.category', false);
		let action = op.get(this.props, 'match.params.action', false);

		return (
			<>
				{ category == 'account' || (category !== 'account' && this?.state?.practiceRound || this?.state?.dailyTournament) ?
						<ReviewContent attributes={{
							practiceRound:this?.state?.practiceRound,
							dailyTournament:this?.state?.dailyTournament,
							category,
							action,
							logo: '/assets/images/nav/anwa_logo.png',
						}} />
					: null
				}
			</>
		)
	}
}

Review.defaultProps = {};
