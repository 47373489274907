 /**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React, { Component } from 'react';
 import { connect } from 'react-redux';
 import deps from 'dependencies';
 import EventsLink from 'shared/general/EventsLink';
 import MeasurementUtils from 'appdir/lib/analytics';
 import op from 'object-path';

 const mapStateToProps = (state, props) => {
     return {
		...state['CookieWarning'],
		site: state?.Config?.dataSettings?.site,
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		...props,
     };
 };
 
 const mapDispatchToProps = (dispatch, props) => ({
     close: () => dispatch(deps.actions.CookieWarning.close()),
 });
 
 class CookieWarning extends Component {
     constructor(props) {
         super(props);
         this.state = {
             ...this.props,
         };
         this.kbClose = this.kbClose.bind(this);
     }
 
     componentDidMount() {
         if (!this.props.closed) {
             document.body.addEventListener('keydown', this.kbClose);
         }
     }
 
     componentWillUnmount() {
         document.body.removeEventListener('keydown', this.kbClose, true);
     }
 
     kbClose(e) {
         if (e.key === 'Escape') {
             this.props.close();
             document.body.removeEventListener('keydown', this.kbClose, true);
         }
     }
 
     onClose = () => {
        MeasurementUtils.dispatchMeasurementCall('cookieWarning', {
			site: this.props.site,
			action: 'close'
		});
         
         this.props.close();
     };
 
     render() {
         if (this.props.closed) {
             return null;
         }
         return (
             <div className="privacy-notice">
                 <div className="privacy-close">
                     <i className="icon-close" role="button" onClick={this.onClose} tabIndex="0" />
                 </div>
                 <span className="header">{`This website uses cookies and similar technologies.`}</span>
                 <span className="content">
                     {`We do this to understand how visitors use our site, improve your experience, and provide content we think might interest you.  For more information see our `}
					 <EventsLink to={this.props?.flowData?.actions?.general?.privacy}>{`Privacy Statement`}</EventsLink>
                 </span>
             </div>
         );
     }
 }
 
 export default connect(mapStateToProps, mapDispatchToProps)(CookieWarning);
 