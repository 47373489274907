/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React from 'react';
 import ForgotPasswordContent from 'shared/page-content/ForgotPasswordContent';

 /**
  * -----------------------------------------------------------------------------
  * React Component: ForgotPassword
  * -----------------------------------------------------------------------------
  */
 
  const ForgotPassword = props => {
	 logger.log('[ForgotPassword] props:%o',props);
	 return <ForgotPasswordContent />
 }
 
 export default ForgotPassword;
 