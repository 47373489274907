/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
import op from 'object-path';
import Nav from 'components/general/Nav';
//import SiteAlert from 'components/general/SiteAlert';
import { connect } from 'react-redux';
import CookieWarning from '../CookieWarning';

const mapStateToProps = (state, props) => ({
	...state.Header,
	settings: state['Config'].dataSettings,
	siteAlertPath: op.get(state['Config'], 'cmsData.alert', ''),
	siteAlert: state['Config']['siteAlert'],
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({
	updateAlert: data => dispatch(deps.actions.Config.updateAlert(data)),
});

/**
 * -----------------------------------------------------------------------------
 * React Component: Header
 * -----------------------------------------------------------------------------
 */

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//...this.props,
		};

		// this.fetchedData = false;
		// this.interval = 30 * 1000;
	}

	componentDidMount() {
		if (this.props.hasOwnProperty('mount')) {
			this.props.mount(this);
		}

		window.addEventListener('scroll', this.resizeHeaderOnScroll);
	}

	componentDidUpdate(prevProps, prevState) {
		// if (this.props.siteAlertPath !== '' && !this.props.siteAlert && !this.fetchedData) {
		// 	this.fetchedData = true;
		// 	this.fetchAlert();

		// 	if (this.timerID !== undefined) {
		// 		clearInterval(this.timerID);
		// 	}

		// 	this.timerID = setInterval(() => {
		// 		this.fetchAlert();
		// 	}, this.interval);
		// }
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.resizeHeaderOnScroll);
		// this.fetchedData = false;

		// if (this.timerID !== undefined) {
		// 	clearInterval(this.timerID);
		// }
	}

	fetchAlert() {
		//logger.log('[Header] - fetchAlert');

		let alertPath = this.props.siteAlertPath;

		if (alertPath !== '') {
			deps.services.Header.fetch(alertPath).then(results => {
				//logger.log('[Header] - fetchAlert results:%o', results);
				this.props.updateAlert(results);
			});
		}
	}

	resizeHeaderOnScroll() {
		const distanceY = window.pageYOffset || document.documentElement.scrollTop,
			shrinkOn = 100,
			headerEl = document.getElementById('anwa-header');

		if (distanceY > shrinkOn) {
			headerEl !== null && headerEl ? headerEl.classList.add('smaller') : null;
		} else {
			headerEl !== null && headerEl ? headerEl.classList.remove('smaller') : null;
		}
	}

	render() {
		let siteAlert = op.get(this.props, 'siteAlert', null);
		let alertText = op.get(siteAlert, 'text', '');

		//let siteAlert = op.get(this.props, 'settings.siteAlert', null);
		let className = siteAlert && (alertText || siteAlert.linkText) ? 'alert' : '';
		return (
			<>
				<header id="anwa-header" className={className}>
					<Nav />
				</header>
				<CookieWarning />
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
