/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';
import HomeContent from 'shared/page-content/HomeContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: ContentPage
 * -----------------------------------------------------------------------------
 */

const Home = props => {
	//logger.log('[Home] props:%o', props);
	const EventsWindow = useSelector(state => state['WindowSize']?.EventsWindow);
	logger.log('[Home Wrapper] EventsWindow:%o', EventsWindow);

	return (
		<>
		{EventsWindow && <HomeContent />}
		</>
	);
};

export default Home;
