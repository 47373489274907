/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React from 'react';
 import ContentPageContent from 'shared/page-content/ContentPageContent';

 /**
  * -----------------------------------------------------------------------------
  * React Component: ContentPage
  * -----------------------------------------------------------------------------
  */
 
  const ContentPage = props => {
	 logger.log('[ContentPage] props:%o',props);
	 return <ContentPageContent />
 }
 
 export default ContentPage;
 