// import 'core-js/modules/es6.string.starts-with';
// import 'core-js/modules/es7.object.entries.js';

//import { App, AppError } from 'reactium-core/app';
import React from 'react';
import { Logger } from 'appdir/logger';
import { getQuerystringValues } from 'appdir/components/general/Util';
import { BroadcastChannel } from 'broadcast-channel';
const anwaChannel = new BroadcastChannel('anwaTickets');

//import deps from 'dependencies';
//import staticData from '../data/json/man/static';

window.logger = Logger;
window.eventsChannel = anwaChannel;

logger.log('anwa-tickets-react');
logger.log('version: %o', window.version);
logger.log('commit: %o', window.commit);
logger.log('env: %o', process.env.NODE_ENV);
logger.log('react version: %o', React.version);

if (window.webview) {
	console.log('[App] webview:%o', window.webview);
	//staticData.webview = true;
}

let parsedQs = getQuerystringValues();

logger.log('querystring: %o', parsedQs);

export const values = {
	pageTitle: "2021 Augusta National Women's Amateur",
	pageDescription: "The Official Site of the Augusta National Women's Amateur 2021.",
	tournamentDates: 'March 31 - April 3 2021',
	tournamentYear: '2021',
	lang: 'en_US',
	xmlLang: 'en_US',
	siteUrl: 'www.anwagolf.com',
	eventId: 'anwa-even',
	configPath: '/en_US/json/gen/config_web_anwa.json',
};

if (parsedQs.config) {
	values.configPath = values.configPath.replace('.json', '.' + parsedQs.config + '.json');
}
