import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.WINDOWSIZE_MOUNT:
			newState = {
				...state,
				...action.data
			};
			//logger.log('[WindowSize] actionTypes.WINDOWSIZE_MOUNT - newState:%o', newState);
			return newState;
			break;
		case deps.actionTypes.WINDOWSIZE_SETWINDOWSIZE:
			newState = {
				...state,
				...action.data
			};
			//logger.log('[WindowSize] actionTypes.WINDOWSIZE_SETWINDOWSIZE - newState:%o', newState);
			return newState;
			break;
		default:
			return state;
	}
};
