/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Template from 'components/Template';
import axios from 'axios';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import Button from 'shared/general/Button';
import VisitButton from 'shared/general/VisitButton';
import LogoutButton from 'shared/general/LogoutButton';
import AccountInfo from 'shared/general/AccountInfo';
import EventsLink from 'shared/general/EventsLink';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import TicketApplication from 'shared/general/TicketApplication';
import ContentHero from 'shared/cms/ContentHero';
import { getApplicationPhase, difference, accountChanged, getPaymentSteps, isAddressEditable, fetchDataFromPaths } from 'shared/general/Util';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import MeasurementUtils from 'appdir/lib/analytics';
import StepIndicator from 'shared/general/StepIndicator';
import { writeErrors } from 'shared/forms/elements/Utils';
import CalendarButton from 'shared/general/CalendarButton';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';




/**
 * -----------------------------------------------------------------------------
 * React Component: ReviewContent
 * -----------------------------------------------------------------------------
 */
export default class ReviewContent extends Component {
	constructor(props) {
		super(props);
		this.handleAction = this.handleAction.bind(this);

		this.state = {
			submit: false,
			submittingApp: false,
			mounted: false,
			message: ''
		};

		this.applicationDataLoaded = false;
		this.tableHighlight = '';
		this.childWindow = null;

		logger.log('[ReviewContent] - constructor this%o', this);
		this.otherAppsLoaded = false;
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: this.getMeasurementTitle(),
			userID: this.props.userData.userID,
			site: this.props.userData.eventName,
		});
	}

	componentDidMount() {
		logger.log('[ReviewContent] - componentDidMount this%o', this);
		this.props.loadConfig();
		this.setState({
			mounted:true
		})
	}


	printPage() {
		// setTimeout(function() {
		// 	window.print();
		// }, 100);
	}

	componentDidUpdate(prevProps, prevState) {
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		let applicationDiff = difference(this.props.tempUserData.application, this.props.userData.application);
		let category = op.get(this.props, 'attributes.category', false);
		let action = op.get(this.props, 'attributes.action', false);

		if (!this.otherAppsLoaded && 
			this?.props?.ticketsData?.applicationUrlList && 
			this?.props?.userData?.token) {
			this.otherAppsLoaded = true;
			logger.log('[ReviewContent] componentDidUpdate - fetchDataFromPaths');

			fetchDataFromPaths(this?.props?.ticketsData?.applicationUrlList, this?.props?.userData?.token)
				.then((result) => {
						logger.log('[ReviewContent] componentDidUpdate - fetchDataFromPaths result:%o', result);

						this.setState({
							applicationData: result,
							editable: isAddressEditable(this?.props?.userData, this?.props?.messageSettings, result)
						}, () => {
							logger.log('[ReviewContent] componentDidUpdate - fetchDataFromPaths state:%o', this.state);

						})
				})
				.catch((error) => {
					logger.error('[ReviewContent] componentDidUpdate - fetchDataFromPaths error:%o', error);

				})
		} 
	}

	componentWillUnmount() {
		this.otherAppsLoaded = false;
	}

	openOtherEvent = (site) => {
		this.childWindow = window.open(site, '_blank');	
		
		let payload = { action: 'login', data: `${this.props?.userData?.secureJwt}|${this.props?.userData?.secureRefreshToken}|${this.props.userData.loginType}` };

		const handleChildLoaded = (event) => {
			logger.log('[ReviewContent] openOtherEvent - handleChildLoaded: event.origin:%o', event.origin);

			if (event.origin !== "https://tickets-cdt.anwagolf.com") {
				return
			}
			if (event.data === 'childLoaded') {
				logger.log('[ReviewContent] openOtherEvent - handleChildLoaded: event:%o', event);

				this.childWindow.postMessage(
				{
					type: 'sso',
					source: 'mastersTickets',
					payload,
				}, 'https://tickets-cdt.anwagolf.com');

				window.removeEventListener('message', handleChildLoaded);
			}
		}

		window.addEventListener('message', handleChildLoaded);
	}


	/**
	 * Once a user has reviewed information when creating an account,
	 * make call to update patron data, then set submit to true for page
	 * redirect
	 */
	onCreateSubmit = () => {
		logger.log('[ReviewContent] onCreateSubmit - this:%o', this);
		let accountDiff = difference(this.props.tempUserData, this.props.userData);
		logger.log('[ReviewContent] onCreateSubmit - accountDiff:%o', accountDiff);

		if (
			accountDiff.addressHash ||
			accountDiff.phone ||
			accountDiff.suffix ||
			accountDiff.prefix ||
			accountDiff.firstName ||
			accountDiff.lastName ||
			accountDiff.address1 ||
			accountDiff.address2 ||
			accountDiff.suiteName ||
			accountDiff.suiteNumber || 
			accountDiff.city ||
			accountDiff.stateProvince || 
			accountDiff.zipPostalCode ||
			accountDiff.zipPlus4
		) {
			this.props.updatePatronData(this.props.userData).then(response => {
				if (response.status == 200) {
					logger.log('[ReviewContent] handleSubmit response:%o', response);
					this.setState(
						{
							submit: true,
							errors: {},
						},
						() => {
							logger.log('[ReviewContent] handleSubmit state:%o', this.state);

							MeasurementUtils.dispatchMeasurementCall(this.getMeasurementTitle(), {
								action: 'Submit',
								status: 'Success',
								userID: this.props.userData.userID,
								site: this.props?.site,
							});
						}
					);
				} else {
					logger.log('[ReviewContent] accept error response:%o', response);
					this.setState(
						{
							...this.state,
							redirect: true,
							submit: false,
							errors: {
								response: `${response.data.errorCode}`,
							},
						},
						() => {
							MeasurementUtils.dispatchMeasurementCall(this.getMeasurementTitle(), {
								action: 'Submit',
								status: 'Failure',
								errors: this.state.errors,
								userID: this.props.userData.userID,
								site: this.props?.site,
							});
						}
					);
				}
			});
		} 
		else {
			// pendingSubmitted is a flag to determing if user submitted changes from the 
			// review screen.   Really has nothing to do with email change other than
			// helping to figure out what text and buttons to show.
			if (this?.props?.pendingEmail) {
				this.props.updateUserData({pendingSubmitted: true})
			}
			this.setState(
				{
					submit: true,
					errors: {},
				},
				() => {
					MeasurementUtils.dispatchMeasurementCall(this.getMeasurementTitle(), {
						action: 'Submit',
						status: 'Success',
						userID: this.props.userData.userID,
						site: this.props?.site,
					});
				}
			);
		}
		//this.props.updateTempUserData(this.props.userData);
	};

	onApplicationSubmit = () => {
		logger.log('[ReviewContent] onApplicationSubmit');
		this.setState(
			{
				submittingApp: true,
			},
			() => {
				axios({
					method: 'post',
					url: this.props.ticketsData.application,
					data: this.props.userData.application,
					headers: { Authorization: `Bearer ${this.props.userData.token}` },
				})
					.then(res => {
						logger.log('[ReviewContent] onApplicationSubmit res:%o', res);
						if (res.status == 200) {
							this.setState(
								{
									submit: true,
									submittingApp: false,
									errors: {},
								},
								() => {
									let data = {
										application: {
											...this.props.userData.application,
											...res.data,
										},
									};

									MeasurementUtils.dispatchMeasurementCall(this.getMeasurementTitle(), {
										action: 'Submit',
										status: 'Success',
										userID: this.props.userData.userID,
										site: this.props?.site,
									});

									logger.log('[ReviewContent] onApplicationSubmit data:%o', data);

									this.props.updateUserData(data);
									this.props.updateTempUserData(data);

									this.setState({
										submit: false,
									});
								}
							);
						} else {
							this.setState(
								{
									submit: false,
									submittingApp: false,
									errors: {
										...this.state.errors,
										response: this?.props?.messageSettings?.['general.service_error']?.replace('{0}', 'AP1') ,
									},
								},
								() => {
									MeasurementUtils.dispatchMeasurementCall(this.getMeasurementTitle(), {
										action: 'Submit',
										status: 'Failure',
										errors: this.state.errors,
										userID: this.props.userData.userID,
										site: this.props?.site,
									});
								}
							);
						}
					})
					.catch(error => {
						logger.error('[ReviewContent] onApplicationSubmit submit application error:%o', error);
						this.setState(
							{
								submit: false,
								submittingApp: false,
								errors: {
									...this.state.errors,
									response: this?.props?.messageSettings?.['general.service_error']?.replace('{0}', 'AP2') ,
								},
							},
							() => {
								MeasurementUtils.dispatchMeasurementCall(this.getMeasurementTitle(), {
									action: 'Submit',
									status: 'Failure',
									errors: this.state.errors,
									userID: this.props.userData.userID,
									site: this.props?.site,
								});
							}
						);
					});
			}
		);
	};

	handlePayment = () => {
		this.setState(
			{
				submit: true,
				errors: {},
			},
			() => {
				this.setState({
					submit: false,
				});
			}
		);
	};

	/**
	 * Checks if user comes to this page with valid state
	 * @returns boolean
	 */
	isReady = () => {
		let category = op.get(this.props, 'attributes.category', false);
		let action = op.get(this.props, 'attributes.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);

		let isReady = false;

		logger.log('[ReviewContent] isReady - this: %o, isReady:%o, category:%o, action:%o, applicationPhase:%o', this, isReady, category, action, applicationPhase);

		if (
			!this.props.userData ||
			isEmpty(this.props.userData) ||
			this.props.siteConfigStatus !== 'loaded' ||
			!this.props.eventConfig ||
			isEmpty(this.props.eventConfig)
		) {
			isReady = false;
		} else {
			if (
				this.props?.userData?.eventName !== 'psb' &&
				(!this.props?.userData?.birthYear ||
					this.props?.userData?.birthYear == 0 ||
					!this.props?.userData?.addressHash ||
					!this.props?.userData?.token)
			) {
				isReady = 'invalid';
			} else if (this.props?.userData?.eventName == 'psb' && !this.props?.userData?.token) {
				isReady = 'invalid';
			}
			// account review
			else if (
				category == 'account' &&
				action == 'review' &&
				this.props.userData &&
				!isEmpty(this.props.flowData)
			) {
				isReady = true;
			} else if (category == 'application' && action == 'review' && applicationPhase == 'applicationAdd') {
				isReady = true;
			}
			// application review
			else if (
				category == 'application' &&
				action == 'review' &&
				op.get(this.props, 'userData.application.applicationStatus', false) &&
				applicationPhase !== 'applicationPaid' &&
				applicationPhase !== 'applicationWinner' &&
				!isEmpty(this.props.flowData)
			) {
				isReady = true;
			}

			// order review -- application is selected "winner"
			else if (
				category == 'payment' &&
				action == 'review' &&
				op.get(this.props, 'userData.application', false) &&
				applicationPhase == 'applicationWinner' &&
				!isEmpty(this.props.flowData)
			) {
				isReady = true;
			}

			// payment confirmation -- application is paid
			else if (
				category == 'payment' &&
				action == 'complete' &&
				applicationPhase == 'applicationPaid' &&
				!isEmpty(this.props.flowData)
			) {
				isReady = true;
			} else {
				isReady = 'invalid';
			}
		}

		logger.log('[ReviewContent] isReady this: %o, isReady:%o', this, isReady);

		return isReady;
	};

	getPageTitle = () => {
		let category = op.get(this.props, 'attributes.category', false);
		let action = op.get(this.props, 'attributes.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);

		if (category == 'account') {
			return 'Account Review';
		} else if (category == 'application' && action == 'review' && applicationPhase == 'applicationAdd') {
			return 'Application Review';
		} else if (
			category == 'application' &&
			action == 'review' &&
			(applicationPhase == 'applicationSubmittedEdit' ||
				applicationPhase == 'applicationSubmittedNoEdit' ||
				applicationPhase == 'applicationWinner' ||
				applicationPhase == 'applicationLoser')
		) {
			return 'Application';
		} else if (category == 'payment' && action == 'review' && applicationPhase == 'applicationWinner') {
			return 'Order Review';
		} else if (category == 'payment' && action == 'complete' && applicationPhase == 'applicationPaid') {
			return 'Payment Confirmation';
		}

		return null;
	};

	getMessageSection = () => {
		let category = op.get(this.props, 'attributes.category', false);
		let action = op.get(this.props, 'attributes.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		let accountDiff = difference(this.props.tempUserData, this.props.userData);
		let applicationDiff = difference(this.props.tempUserData.application, this.props.userData.application);
		logger.log('[ReviewContent] getMessageSection accountChanged: %o, pendingEmail: %o, accountDiff: %o', accountChanged(accountDiff), this.props.pendingEmail, accountDiff);
		let paymentSteps = getPaymentSteps(this?.props?.userData, this?.props?.messageSettings?.['system.mobile_verification'], this?.props?.eventConfig, this?.props?.requiresVerifyId);
    	logger.log('[ReviewContent] getMessageSection editable: %o' ,this?.state?.editable)

		if (
			category == 'account' &&
			action == 'review' &&
			(accountChanged(accountDiff) || (this.props.pendingEmail && !this?.props?.userData?.pendingSubmitted)) &&
			applicationPhase !== 'applicationAdd'
		) {
			return <CmsMessageContent id="account.regonly_review" />;
		} else if (
			category == 'account' &&
			action == 'review' &&
			applicationPhase == 'registration' &&
			!accountChanged(accountDiff) &&
			(!this.props.pendingEmail || this?.props?.userData?.pendingSubmitted)
			//isEqual(this.props.tempUserData, this.props.userData)
		) {
			return this.props.userData.eventName !== "psb" ? (
        <>
          <CmsMessageContent id="account.regonly_confirmation" />
		  {this.renderCalendarButton()}
        </>
      ) : (
        <>
          {this.props.userData.addressValidationStatusId == 12 ? (
            <CmsMessageContent id="application.no_application_12" />
          ) : (
            <>
              {this?.state?.editable ? (
                <CmsMessageContent id="application.no_application" />
              ) : (
                <CmsMessageContent id="application.no_application_1" />
              )}
            </>
          )}
        </>
      );
		} else if (category == 'account' && action == 'review' && applicationPhase == 'applicationAdd') {
			return !this.props.validStatusIds[this.props.userData.addressValidationStatusId] 
				? <CmsMessageContent id="application.existing_user_instructions" data={[`<span data-cms="account.submit_button">${this?.props?.messageSettings?.['account.submit_button']}</span>`]} />
				: <CmsMessageContent id="application.existing_user_instructions" data={[`<span data-cms="account.submit_next_button">${this?.props?.messageSettings?.['account.submit_next_button']}</span>`]} />;
		} else if (
			category == 'application' &&
			action == 'review' &&
			(applicationPhase == 'applicationAdd' ||
				(applicationPhase == 'applicationSubmittedEdit' && !isEmpty(applicationDiff)))
		) {
			return <CmsMessageContent id="application.review_instructions" />;
		} else if (
			category == 'application' &&
			action == 'review' &&
			(applicationPhase == 'applicationSubmittedEdit' || applicationPhase == 'applicationSubmittedNoEdit')
		) {
			return applicationPhase == 'applicationSubmittedEdit' ? (
				<>
					<CmsMessageContent id="application.app_received_edit" />
				</>
			) : (
				<CmsMessageContent id="application.app_received_no_edit" />
			);
		} else if (category == 'application' && action == 'review' && applicationPhase == 'applicationLoser') {
			return (
				<>
					<CmsMessageContent id="application.not_selected" />
					{this.renderCalendarButton()}
				</>
       );
		} else if (
			category == 'payment' &&
			action == 'review' &&
			applicationPhase == 'applicationWinner' &&
			!this.props.eventConfig.allowPayment
		) {
			return <CmsMessageContent id="payment.splash_payments_expired" />;
		} else if (
			category == 'payment' &&
			action == 'review' &&
			applicationPhase == 'applicationWinner' &&
			this.props.eventConfig.allowPayment
		) {
			return (
        <>
		  <StepIndicator
			// stepLabels={this?.props?.messageSettings['payment.steps_title']}
			type="winner"
			showWinnerSplash={false}
			stepLabels={this?.props?.messageSettings?.[paymentSteps?.id]}
			paymentSteps={paymentSteps}
			currentStep={
				this?.props?.userData?.application?.hasAcceptedTicketAgreement
				    && this?.props?.messageSettings?.['system.mobile_verification'] === 'true'
					&& this?.props?.userData?.addressValidationStatusId == 12
					&& this?.props?.userData?.phoneVerifiedCount > 0
					&& !this?.props?.userData?.datePhoneVerified
					&& this.props.requiresVerifyId[this.props.userData.country]
				? 3
				: 1
			}
			category={category}
			action={action}
			handleAction={this.handleAction}
		  />
          {/* <StepIndicator
            // stepLabels={this?.props?.messageSettings['payment.steps_title']}
            type="winner"
            showWinnerSplash={false}
            stepLabels={this?.props?.messageSettings['payment.steps_title']}
            currentStep={1}
            category={category}
            action={action}
            handleAction={this.handleAction}
          /> */}
          <CmsMessageContent id="payment.review_instructions" data={['00']} />
        </>
      );
		} else if (category == 'payment' && action == 'complete' && applicationPhase == 'applicationPaid') {
			return (
        <>
          <StepIndicator
            // stepLabels={this?.props?.messageSettings['payment.steps_title']}
            type="winner"
            showWinnerSplash={false}
            stepLabels={this?.props?.messageSettings?.[paymentSteps?.id]}
            currentStep={paymentSteps?.currentStep}
            handleAction={this.handleAction}
          />
          <>
            {this?.state?.editable ? (
				<CmsMessageContent id="payment.confirmation" data={[this.props.userData.email]} />
			) : (
				<CmsMessageContent id="payment.confirmation_no_address_edit" data={[this.props.userData.email]} />
			)}
          </>
        </>
      );
		} else if (this.isReady() == 'invalid') {
			return (
				<>
					<CmsMessageContent id="general.service_error" data={["INV"]} />
					<CmsMessageContent id="general.further_assistance" />
				</>
			);
		}

		return null;
	};

	getPageActions = () => {
		let category = op.get(this.props, 'attributes.category', false);
		let action = op.get(this.props, 'attributes.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		let accountDiff = difference(this.props.tempUserData, this.props.userData);
		let applicationDiff = difference(this.props.tempUserData.application, this.props.userData.application);
		logger.log(
			'[ReviewContent] getPageActions category:%o, action:%o, applicationPhase:%o, accountDiff:%o, applicationDiff:%o',
			category,
			action,
			applicationPhase,
			accountDiff,
			applicationDiff
		);

		if (this.isReady() == 'invalid') {
			return (
				<>
					<LogoutButton type="button" />
					<VisitButton />
				</>
			);
		}

		/** Account */
		if (
			category == 'account' &&
			action == 'review' &&
			applicationPhase == 'registration' &&
			(accountChanged(accountDiff) || (this.props.pendingEmail && !this?.props?.userData?.pendingSubmitted))			//!isEqual(this.props.tempUserData, this.props.userData)
		) {
			logger.log('[ReviewContent] getPageActions 1');

			return (
				<>
					<Button buttonText={this?.props?.messageSettings?.['account.submit_button']} style="full" onClick={this.onCreateSubmit} />
					<div className="action-link-container">
						<EventsLink
							onClick={() => this.handleAction('Previous')}
							to={
								op.get(this.props, 'tempUserData.birthYear') === 0 ||
								!op.get(this.props, 'tempUserData.birthYear')
									? this.props.flowData.actions.accountReview.previous
									: this.props.flowData.actions.accountProfile.next
							}
							style="action-link">
							Previous
						</EventsLink>
					</div>

					<div className="action-link-container">
						<EventsLink
							style="action-link"
							onClick={() => this.handleAction('Cancel')}
							to={this.props.flowData.actions.accountReview.cancel}>
							Cancel
						</EventsLink>
					</div>
				</>
			);
		} else if (
			category == 'account' &&
			action == 'review' &&
			applicationPhase == 'applicationPaid' &&
			(accountChanged(accountDiff) || (this.props.pendingEmail && !this?.props?.userData?.pendingSubmitted))			//!isEqual(this.props.tempUserData, this.props.userData)
		) {
			logger.log('[ReviewContent] getPageActions 2');

			return (
				<>
					<Button buttonText={this?.props?.messageSettings?.['account.submit_button']} style="full" onClick={this.onCreateSubmit} />
					<div className="action-link-container">
						<EventsLink
							style="action-link"
							onClick={() => this.handleAction('Cancel')}
							to={this.props.flowData.actions.accountReview.cancel}>
							Cancel
						</EventsLink>
					</div>
				</>
			);
		} else if (
			category == 'account' &&
			action == 'review' &&
			applicationPhase == 'registration' &&
			!accountChanged(accountDiff) &&
			(!this.props.pendingEmail || this?.props?.userData?.pendingSubmitted)
		) {
			logger.log('[ReviewContent] getPageActions 3');

			return (
				<>
					<Button
					buttonText="Print Account Information"
					style="full"
					external={true}
					to={this?.props?.flowData?.actions?.print?.accountReview}
					  />
					{/* <VisitButton type={this.props.userData.eventName == 'psb' ? 'button' : ''} /> */}
          <VisitButton />
					<LogoutButton />
				</>
			);
		} else if (
			/** Account - after application is open, new application open or submitted application editable */
			category == 'account' &&
			action == 'review' &&
			(applicationPhase == 'applicationSubmittedEdit' ||
				applicationPhase == 'applicationSubmittedNoEdit' ||
				applicationPhase == 'applicationWinner' ||
				applicationPhase == 'applicationLoser') &&
				(accountChanged(accountDiff) || (this.props.pendingEmail && !this?.props?.userData?.pendingSubmitted))			
		) {
			logger.log('[ReviewContent] getPageActions 4');
			return (
				<>
					{logger.log('[ReviewContent] getPageActions 4-1')}
					<Button buttonText={this?.props?.messageSettings?.['account.submit_button']} style="full" onClick={this.onCreateSubmit} />
					<div className="action-link-container">
						<EventsLink
							style="action-link"
							onClick={() => this.handleAction('Previous')}
							to={this.props.flowData.actions.accountProfile.next}>
							Previous
						</EventsLink>
					</div>

					<div className="action-link-container">
						<EventsLink
							style="action-link"
							onClick={() => this.handleAction('Cancel')}
							to={this.props.flowData.actions.accountReview.cancel}>
							Cancel
						</EventsLink>
					</div>
				</>
			);
		} else if (
			category == 'account' &&
			action == 'review' &&
			applicationPhase == 'applicationAdd' &&
			!accountChanged(accountDiff) &&
			(!this.props.pendingEmail || this?.props?.userData?.pendingSubmitted)
		) {
			logger.log('[ReviewContent] getPageActions 5');

			return (
				<>
					{!this.props.validStatusIds[this.props.userData.addressValidationStatusId] ? (
						<>
							{
								applicationPhase == 'applicationAdd' ? (
									<Button buttonText={this?.props?.messageSettings?.['account.submit_next_button']} style="full" buttonState="disabled" />
								) : (
									<Button buttonText={this?.props?.messageSettings?.['account.submit_button']} style="full" buttonState="disabled" />
								)
							}
						</>
					) : (
						<Button buttonText={this?.props?.messageSettings?.['account.submit_next_button']} style="full" onClick={this.onCreateSubmit} />
					)}
					<VisitButton />
					<LogoutButton />
				</>
			);
		} else if (
			category == 'account' &&
			action == 'review' &&
			applicationPhase == 'applicationAdd' &&
			(accountChanged(accountDiff) || (this.props.pendingEmail && !this?.props?.userData?.pendingSubmitted))			//!isEqual(this.props.tempUserData, this.props.userData)
		) {
			logger.log('[ReviewContent] getPageActions 6');

			return (
				<>
					{!this.props.validStatusIds[this.props.userData.addressValidationStatusId] ? (
						<Button buttonText={this?.props?.messageSettings?.['account.submit_button']} style="full" onClick={this.onCreateSubmit} />
					) : (
						<Button buttonText={this?.props?.messageSettings?.['account.submit_next_button']} style="full" onClick={this.onCreateSubmit} />
					)}
					<div className="action-link-container">
						<EventsLink
							style="action-link"
							onClick={() => this.handleAction('Previous')}
							to={this.props.flowData.actions.accountProfile.next}>
							Previous
						</EventsLink>
					</div>

					<div className="action-link-container">
						<EventsLink
							style="action-link"
							onClick={() => this.handleAction('Cancel')}
							to={this.props.flowData.actions.accountReview.cancel}>
							Cancel
						</EventsLink>
					</div>
				</>
			);
		} else if (
			/**
			 *  application (submitted and editable)
			 * 	open and no app
			 */
			category == 'application' &&
			action == 'review' &&
			(applicationPhase == 'applicationAdd' ||
				(applicationPhase == 'applicationSubmittedEdit' && !isEmpty(applicationDiff)))
			//!isEqual(this.props.userData, this.props.tempUserData)))
		) {
			logger.log('[ReviewContent] getPageActions 7');

			return (
				<>
					{this.state.submittingApp ? (
						<Button buttonText="Submit" style="full" buttonState="disabled" />
					) : (
						<Button buttonText="Submit" style="full" onClick={this.onApplicationSubmit} />
					)}

					<div className="action-link-container">
						<EventsLink
							onClick={() => this.handleAction('Previous')}
							to={this.props.flowData.actions.applicationReview.previous}
							style="action-link">
							Previous
						</EventsLink>
					</div>

					<div className="action-link-container">
						<EventsLink
							onClick={() => this.handleAction('Cancel')}
							to={this.props.flowData.actions.ticketApplication.cancel}
							style="action-link">
							Cancel
						</EventsLink>
					</div>
				</>
			);
		} else if (
			/** application submitted and not editable */
			category == 'application' &&
			action == 'review' &&
			(applicationPhase == 'applicationSubmittedNoEdit' ||
				applicationPhase == 'applicationWinner' ||
				applicationPhase == 'applicationLoser' ||
				(applicationPhase == 'applicationSubmittedEdit' && isEmpty(applicationDiff)))
			//isEqual(this.props.userData, this.props.tempUserData)))
		) {
			logger.log('[ReviewContent] getPageActions 8');

			return (
				<>
					<Button
						buttonText="Print Application"
						style="full"
						external={true}
						to={this?.props?.flowData?.actions?.print?.applicationReview}
					  />

					<VisitButton />
					<LogoutButton type="link" />
				</>
			);
		} else if (
			category == 'payment' &&
			action == 'review' &&
			applicationPhase == 'applicationWinner' &&
			this.props.eventConfig.allowPayment
		) {
			logger.log('[ReviewContent] getPageActions 9');
			return (
				<>
					{this.props.userData.addressValidationStatusId == 12 
					? (
						<Button buttonText="Next" buttonState="disabled" style="full" />
					) : (
						<Button
							buttonText="Next"
							style="full"
							onClick={() => this.handleAction('Next')}
							to={
								(this?.props?.userData?.application?.hasAcceptedTicketAgreement && this.props?.messageSettings?.['system.mobile_verification'] === "true" && this.props.requiresVerifyId[this.props.userData.country])
									? this.props.flowData.actions.notification.verifyidentity
                  					: this?.props?.userData?.application?.hasAcceptedTicketAgreement
										? this.props.flowData.actions.paymentReview.payment
										: this.props.flowData.actions.paymentReview.terms
							}
						/>
					)}
					<LogoutButton />
				</>
			);
		} else if (
			category == 'payment' &&
			action == 'review' &&
			applicationPhase == 'applicationWinner' &&
			!this.props.eventConfig.allowPayment
		) {
			return (
				<>
          <Button
            buttonText="Print Application"
				style="full"
				external={true}
				to={this?.props?.flowData?.actions?.print?.paymentReview}
          />
					<LogoutButton type="link" />
					<VisitButton />
				</>
			);
		} else if (category == 'payment' && action == 'complete' && applicationPhase == 'applicationPaid') {
			logger.log('[ReviewContent] getPageActions 10');

			return (
				<>
					<Button
						buttonText="Print Receipt"
						style="full"
						external={true}
						to={this?.props?.flowData?.actions?.print?.paymentComplete}
					/>
					<VisitButton />
					<LogoutButton />
				</>
			);
		} 
		// else {
		// 	return (
		// 		<Redirect
		// 			push
		// 			to={this.props.flowData.actions.general.logout}
		// 		/>
		// 	);
		// }
	};

	getApplicationStatus = () => {
		let category = op.get(this.props, 'attributes.category', false);
		let action = op.get(this.props, 'attributes.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		let status = '';
		let applicationDiff = difference(this.props.tempUserData.application, this.props.userData.application);

		if (category == 'application' && applicationPhase == 'applicationAdd') {
			status = 'Entry Status: Not Submitted';
		} else if (
			(category == 'application' && applicationPhase == 'applicationSubmittedEdit') ||
			applicationPhase == 'applicationSubmittedNoEdit'
		) {
			status = isEmpty(applicationDiff)
				? 'Application Status: Submitted and Received'
				: 'Application Status: Changes Not Submitted';
		} else if (category == 'application' && applicationPhase == 'applicationLoser') {
			status = 'Application Status: Application Not Selected';
		} else if (category == 'application' && applicationPhase == 'applicationWinner') {
			status = 'Application Status: Application Selected';
		} else if (category == 'payment' && applicationPhase == 'applicationPaid') {
			status == 'Application Status: Payment Received';
		}

		if (status !== '') {
			return (
				<div id="application-status" className="text">
					<strong>{status}</strong>
				</div>
			);
		} else {
			return null;
		}
	};

	getOtherEventLinks = () => {
		let category = op.get(this.props, 'attributes.category', false);
		let action = op.get(this.props, 'attributes.action', false);
		let applicationDiff = difference(this.props.tempUserData.application, this.props.userData.application);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);

		if (
			(category == 'application' && action=='review' && applicationPhase == 'applicationSubmittedEdit' && isEmpty(applicationDiff) &&
			(this.state?.applicationData?.masters?.patronId || this.state?.applicationData?.anwa?.patronId || this.state?.applicationData?.dcp?.patronId )

		)
		) {
			return (
				<div className='page-actions-container'>
					<CmsMessageContent id="application.apply_for_other_events" />
					<div id='confirmation-page-action' className="page-actions">
						{this.state?.applicationData?.masters?.patronId ? 
							<Button 
							buttonText={this?.props?.messageSettings?.['application.access_masters_button']}
							//to={this.props.flowData.actions.general.mastersSiteUrl+`\?email=${this.props?.userData?.email}`}
							onClick={() => this.openOtherEvent(this.props.flowData.actions.general.mastersSiteUrl+`\?email=${this.props?.userData?.email}`)}
							/>
						: null }
						{this.state?.applicationData?.anwa?.patronId ? 
							<Button 
							buttonText={this?.props?.messageSettings?.['application.access_anwa_button']}
							//to={this.props.flowData.actions.general.anwaSiteUrl+`\?email=${this.props?.userData?.email}`} 
							//onClick={() => this.openOtherEvent(this.props.flowData.actions.general.anwaSiteUrl+`\?email=${this.props?.userData?.email}`)}
							onClick={() => this.openOtherEvent(`https://tickets-cdt.anwagolf.com/en_US/index.html`+`\?email=${this.props?.userData?.email}`)}

							/>
						: null }
						{this.state?.applicationData?.dcp?.patronId ? 
							<Button 
							buttonText={this?.props?.messageSettings?.['application.access_dcp_button']}
							//to={this.props.flowData.actions.general.dcpSiteUrl+`\?email=${this.props?.userData?.email}`} 
							onClick={() => this.openOtherEvent(this.props.flowData.actions.general.dcpSiteUrl+`\?email=${this.props?.userData?.email}`)}
							/>
						: null }
					</div>
				</div>
			)
		} else {
			return null
		}
		
	}

	getErrorSection = () => {
		let category = op.get(this.props, 'attributes.category', false);
		let action = op.get(this.props, 'attributes.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);

		return (
			<>
				{this?.props?.invalidStatusIds?.[this.props.userData.addressValidationStatusId] ? (
					<div className="return-error">
						{/* {applicationPhase == "applicationAdd" 
						? <CmsMessageContent id="account.invalid_address_plea" />
						: <CmsMessageContent id="account.invalid_address" />
						} */}
						{/* awt:  removing check for applicationAdd */}
						<CmsMessageContent id="account.invalid_address_plea" />
					</div>
				) : null}

				{this?.props?.userData?.addressValidationStatusId == 12 ? (
					<div className="return-error">
						{this.props.userData.eventName !== 'psb' 
							? <CmsMessageContent id="payment.coa_homepage_instructions" />
							: <CmsMessageContent id="payment.coa_homepage_instructions_12" />
						}
					</div>
				) : null}

				{this?.props?.userData?.addressValidationStatusId == 12 &&
				applicationPhase == 'applicationWinner' &&
				this.props.eventConfig.allowPayment ? (
					<div className="return-error">
						<CmsMessageContent id="payment.coa_payment_disabled_instructions" />
					</div>
				) : null}
			</>
		);
	};

	getMeasurementTitle() {
		let category = op.get(this.props, 'attributes.category', '').replace(/^\w/, c => c.toUpperCase());
		let action = op.get(this.props, 'attributes.action', '').replace(/^\w/, c => c.toUpperCase());

		return category + action;
	}

	handleAction(which) {
		MeasurementUtils.dispatchMeasurementCall(this.getMeasurementTitle(), {
			action: which,
			userID: this.props.tempUserData.userID,
			site: this.props?.site,
		});
	}

	renderCalendarButton() {
		let emailOptions = this?.props?.messageSettings?.['general.list_of_email_vendors']?.split(', ');
		logger.log('[ReviewContent] renderCalendarButton - emailOptions:%o', emailOptions);

		return (<CalendarButton
			data={{
				type: 'CalendarButton',
				name: this.props.messageSettings?.['general.event_reminder_title'],
				description: this.props.messageSettings?.[
					'general.event_reminder_description'
				],
				startDate: moment
					.tz(
						this.props.messageSettings?.[
							'general.event_reminder_startTime'
						],
						'America/New_York'
					)
					.format('YYYY-MM-DD'),
				endDate: moment
					.tz(
						this.props.messageSettings?.[
							'general.event_reminder_endTime'
						],
						'America/New_York'
					)
					.format('YYYY-MM-DD'),
				startTime: moment
					.tz(
						this.props.messageSettings?.[
							'general.event_reminder_startTime'
						],
						'America/New_York'
					)
					.format('HH:mm'),
				endTime: moment
					.tz(
						this.props.messageSettings?.[
							'general.event_reminder_startTime'
						],
						'America/New_York'
					)
					.format('HH:mm'),
				location: this.props.messageSettings?.[
					'general.event_reminder_location'
				],
				label: this.props.messageSettings?.[
					'general.event_reminder_button_label'
				],
				identifier: 'tickets',
				options:this.props.messageSettings?.['general.list_of_email_vendors']?.replace(', ', ',')?.split(',')
			}}
		/>)
	}

	render() {
		logger.log('[ReviewContent] render - this:%o', this);

		let category = op.get(this.props, 'attributes.category', false);
		let action = op.get(this.props, 'attributes.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);

		if (this.state.redirect === true && !isEmpty(this.props.flowData)) {
			let verifiedAddress = this.props.userData;
			let userAddress = null;
			if (op.get(verifiedAddress, 'address1', false)) {
				userAddress = `${verifiedAddress.address1}<br/>`;

				if (op.get(verifiedAddress, 'address2', null)) {
					userAddress += `${verifiedAddress.address2}<br/>`;
				}

				if (op.get(verifiedAddress, 'suiteName', null)) {
					userAddress += `${verifiedAddress.suiteName} `;
				}

				if (op.get(verifiedAddress, 'suiteNumber', null)) {
					userAddress += `${verifiedAddress.suiteNumber}<br/>`;
				}

				userAddress += `${verifiedAddress.city}, ${verifiedAddress.stateProvince} ${verifiedAddress.zipPostalCode}`;

				if (op.get(verifiedAddress, 'zipPlus4', null)) {
					userAddress += `-${verifiedAddress.zipPlus4}<br/>`;
				} else {
					userAddress += '<br/>';
				}

				userAddress += `${verifiedAddress.country}`;
			}
			return (
				<Redirect
					push
					to={{
						pathname: this.props.flowData.actions.addressVerification.change,
						state: { errors: this.state.errors },
					}}
				/>
			);
		}

		if (this.state.submit === true && !isEmpty(this.props.flowData)) {
			logger.log('[ReviewContent] render - submitted');

			let url;
			let { flowData } = this.props;

			switch (category) {
				case 'account':
					if (
						applicationPhase == 'applicationAdd' &&
						this.props.validStatusIds[this.props.userData.addressValidationStatusId]
					) {
						url = flowData.actions.accountReview.next;
					} else {
						url = flowData.actions.accountReview.submit;
					}
					break;
				case 'application':
					url = flowData.actions.applicationReview.submit;
					break;
				case 'payment':
					url = flowData.actions.paymentReview.payment;
					break;
				default:
					url = flowData.actions.accountReview.submit;
					break;
			}
			logger.log('[ReviewContent] render - redirecting:%o', url);

			return <Redirect push to={url} />;
		}
		if (this.isReady() == 'invalid' && this.props?.flowData?.actions?.general?.signin) {
			return <Redirect push to={this.props.flowData.actions.general.signin} />;
		} else {
			return (
				<Template>
					<section id="print-area" className="page-content review-page">
						<img className="printLogo" src={this?.props?.attributes?.logo ? this?.props?.attributes?.logo : '/assets/images/nav/masters_logo.jpg'} />
						<section className="page-title">
							<h1>
								<CmsMessageContent id="general.page_header_title" textOnly={true} />
							</h1>
						</section>
						<section className="hero">
							<ContentHero />
						</section>
						<section className="section-title-wrapper">
							<h2>{this.getPageTitle()}</h2>
						</section>
						<section className="page-grid cols-1 review-page" id="review-page">
							<div className="page-grid-item">
								{!this.props?.userData?.token || !this.isReady() || isEmpty(this.props.flowData) ? (
									<LoadingIndicator />
								) : this.isReady() && this.isReady() !== 'invalid' ? (
									<>
										<div className="form-content">{writeErrors(null, this.state.errors)}</div>
										{this.getMessageSection()}
										{this.getErrorSection()}

										{this.getApplicationStatus()}
										{this.getOtherEventLinks()}
										<AccountInfo
											category={
												category == 'payment' && action == 'complete' ? 'receipt' : category
											}
											userData={this.props.userData}
											eventData={this.props.eventConfig}
											action={action}
											editable={this.state.editable}
										/>
										{category == 'application' || category == 'payment' ? (
											<div className="ticket-info">
												<div className="header">
													<h3>
														<CmsMessageContent id="application.header" textOnly={true} />
													</h3>
													<div className="edit-account-info">
														{category == 'application' &&
														(applicationPhase == 'applicationAdd' ||
															applicationPhase == 'applicationSubmittedEdit') ? (
															<EventsLink to={this.props.flowData.login.application}>
																Edit Your Application
															</EventsLink>
														) : null}
													</div>
												</div>

												{op.get(this.props, 'attributes.practiceRound', false) ? (
													<TicketApplication
														data={this.props.attributes.practiceRound}
														title={
															this.props.userData.eventName !== 'psb'
																? this.props.userData.eventName == 'masters' ? 'Practice Round Tickets': 'Tournament Tickets'
																: ''
														}
														subTitle={
															(category == 'payment' && action == 'complete') ||
															this.props.userData.eventName == 'psb'
																? ''
																: `Maximum ${this.props.eventConfig.day1MaxTickets} per day`
														}
														highlight={this.tableHighlight}
													/>
												) : null}

												{op.get(this.props, 'attributes.dailyTournament', false) ? (
													<TicketApplication
														data={this.props.attributes.dailyTournament}
														title="Daily Tournament Tickets"
														subTitle={
															category == 'payment' && action == 'complete'
																? ''
																: `Maximum ${this.props.eventConfig.day4MaxTickets} per day`
														}
														highlight={this.tableHighlight}
													/>
												) : null}
												{category == 'payment' ? (
													<div className="total">
														{action == 'review' ? 'Total Amount Due' : 'Total Amount Paid'}
														:&nbsp;&nbsp;&nbsp;$
														{action == 'review'
															? op
																	.get(this.props, 'userData.application.amtDue', 0)
																	.toFixed(2)
															: action == 'complete'
															? op
																	.get(this.props, 'userData.application.amtPaid', 0)
																	.toFixed(2)
															: null}
													</div>
												) : null}

											</div>
										) : null}

										{category == 'payment' && action == 'complete' && this.props?.site == 'psb' ? (
											<div className="jrpass-info">
												<div className="header">
													<h3>Jr. Pass Program</h3>
													<div className="edit-info">
														<EventsLink to={this?.props?.flowData?.actions?.paymentComplete?.editJrPass}>
															Edit Your Response
														</EventsLink>
													</div>
												</div>
												<div className="jrpass-data">
													 	{this?.props?.userData?.application?.jrPassIntendToUse ? (
															// user has chosen to use jr pass
															<>
																		<div className="selected-response"><CmsMessageContent id="payment.jr_pass_yes" textOnly={true} /></div>
																		<ul className="selected-days">
																			{
																			["jrPassThu","jrPassFri","jrPassSat","jrPassSun"].map((day,i) => {
																				//logger.log('[ReviewContent] render - jrPass loop day:%o, index:%o, data:%o', day, i+1, this.props.userData.application[day]);
																				return this.props.userData.application[day] ? (
																					<li><CmsMessageContent id={`payment.jr_pass_day_${i+1}`} textOnly={true} /></li>
																				) : null
																			})
																			}
																		</ul>
																	</>
														) : (
															<>
																{this?.props?.userData?.application?.jrPassAgreement ? (
																	// user has explicitly chosen not to use jr pass
																	<div className="selected-response"><CmsMessageContent id="payment.jr_pass_no" textOnly={true} /></div>
																	) : (
																	// user has not made any selection for jr pass
																	<CmsMessageContent id="payment.jr_pass_no_selection" />
																)}
															</>
														)}

												</div>
											</div>
										) : null }
										<div className="page-actions">{this.getPageActions()}</div>
									</>
								) : (
									<>
										{this.getMessageSection()}
										<div className="page-actions">{this.getPageActions()}</div>
									</>
								)}
							</div>
						</section>
					</section>
				</Template>
			);
		}
	}
}

ReviewContent.defaultProps = {};
