import React, { Component } from 'react';
import EventsLink from 'shared/general/EventsLink';

class SponsorList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.interval = 15 * 1000;
		this.firstload = true;
		this._isMounted = false;
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
		this._isMounted = false;
	}

	componentDidMount() {
		logger.log('[SponsorList] componentDidMount - this: %o', this);
		if (this.timerID !== undefined) {
			clearInterval(this.timerID);
		}

		this.firstload = false;
		this._isMounted = true;

		this.getRandomStart();

		this.timerID = setInterval(() => {
			this.rotate();
		}, this.interval);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	getRandomStart() {
		// logger.log('[SponsorList] getRandomStart - this: %o', this);

		var min = 0,
			max = 5;

		if (this._isMounted) {
			this.setState({ 
				counter: Math.floor(Math.random() * max) + min
			});
		}
	}

	rotate() {
		// logger.log('[SponsorList] rotate - this: %o', this);
		let tmp_counter;

		if (this.state.counter >= 0) {
			tmp_counter = this.state.counter;

			tmp_counter += 1;

			if (tmp_counter >= 5) {
				tmp_counter -= 5;
			}
		}

		if (this._isMounted) {
			this.setState({ 
				counter: tmp_counter 
			});
		}
	}

	render() {
		// logger.log('[SponsorList] render - this: %o', this);

		let { sponsors } = this.props;

		if (this.state.counter >= 0) {
			return (
				<div id="sponsors" className="sponsors">
					{sponsors.map((item, i) => {
						let showClass = i === this.state.counter ? 'show' : '';
						return (
							<div className={`${item.class} ${showClass} `} key={`nav-${i}`}>
								<EventsLink to={item.to} external={true}>
									<img src={item.imgsrc} height={item.height} width={item.width} alt={item.label} />
								</EventsLink>
							</div>
						);
					})}
				</div>
			);
		} else {
			return null;
		}
	}
}

SponsorList.defaultProps = {
	sponsors: [
		{
			class: 'ibm',
			exact: true,
			to: 'http://www.ibm.com/',
			imgsrc: '/assets/images/nav/ibm_logo.png',
			width: '55',
			height: '20',
			label: 'IBM',
		},
		{
			class: 'att',
			exact: true,
			to: 'http://www.att.com/',
			imgsrc: '/assets/images/nav/att_logo.png',
			width: '61',
			height: '25',
			label: 'AT&T',
		},
		{
			class: 'mb',
			exact: true,
			to: 'http://www.mbusa.com/',
			imgsrc: '/assets/images/nav/mercedes.png',
			width: '32',
			height: '32',
			label: 'Mercedes',
		},
		{
			class: 'boa',
			exact: true,
			to:
				'https://about.bankofamerica.com/en-us/index.html?s_src=Augusta_National_Golf_BofA_Logo_Enterprise_AboutUs',
			imgsrc: '/assets/images/nav/boa_logo.png',
			width: '61',
			height: '21',
			label: 'Bank of America',
		},
		{
			class: 'rolex',
			exact: true,
			to: 'https://www.rolex.com/',
			imgsrc: '/assets/images/nav/rolex_logo.png',
			width: '34',
			height: '16',
			label: 'Rolex',
		},
	],
};

export default SponsorList;
