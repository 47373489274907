/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import EventsLink from 'shared/general/EventsLink';
import { getApplicationPhase } from 'shared/general/Util';
import op from 'object-path';
import { connect } from 'react-redux';
import CmsMessageContent from 'shared/general/CmsMessageContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: Footer
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state.Footer,
		eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
		userData: op.get(state['UserData'], 'userData', {}),
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		messageSettings: state?.['Config']?.staticData?.cmsMessages?.data
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
});

class Footer extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.hasOwnProperty('mount')) {
			this.props.mount(this);
		}
	}

	render() {
		let year = this.props.year || this.props.year !== '' ? this.props.year : 2020;

		let applicationPhase = getApplicationPhase(this.props.userData.application, this.props.eventConfig);
		logger.log('[footer - getApplicationPhase]', applicationPhase);
		let winnerPrintLink;
		if (applicationPhase == 'applicationWinner' || applicationPhase == 'applicationPaid') {
			winnerPrintLink = (
				<li className="list-inline-item">
					<EventsLink data-bypass="" to={this.props?.flowData?.actions?.general?.terms} external="true">
						Printable Terms & Conditions
					</EventsLink>
				</li>
			);
		} else {
			winnerPrintLink = '';
		}

		return (
			<footer id="bottom">
				<div className="container">
					<div className="row">
						<div className="col">
							<ul className="list-inline">
								<li className="list-inline-item bold copyright">
									<div>&copy; <CmsMessageContent className="bold" id="copyright_year" textOnly={true} />{' '}
									Augusta National Women's Amateur, LLC</div>
									<i className="icon-augusta_logo" />
								</li>
								<li className="list-inline-item">
									<EventsLink to={this.props?.flowData?.actions?.general?.privacy}>
										Privacy Statement
									</EventsLink>
								</li>
								{winnerPrintLink}
								<li className="list-inline-item">
									<EventsLink to={this.props?.flowData?.actions?.general?.termsOfUse} external="true">
										Terms of Use
									</EventsLink>
								</li>
								<li className="list-inline-item">
								<EventsLink to={`${this?.props?.messageSettings?.website_url}/en_US/feedback/index.html`} external="true">Feedback</EventsLink>
								</li>
							</ul>
						</div>
						<div className="col right">
							<ul className="list-inline">
								<li className="list-inline-item facebook">
									<EventsLink
										data-bypass=""
										to={this.props?.flowData?.actions?.general?.facebook}
										external="true">
										<i className='icon-facebook'/>
									</EventsLink>
								</li>
								<li className="list-inline-item instagram">
									<EventsLink
										data-bypass=""
										to={this.props?.flowData?.actions?.general?.instagram}
										external="true">
										<i className='icon-instagram'/>
									</EventsLink>
								</li>
								<li className="list-inline-item twitter">
									<EventsLink
										data-bypass=""
										to={this.props?.flowData?.actions?.general?.twitter}
										external="true">
										<i className='icon-twitter'/>
									</EventsLink>
								</li>
								<li className="list-inline-item tiktok">
									<EventsLink data-bypass="" to="https://www.tiktok.com/@anwagolf" external="true">
										<img src="/assets/images/nav/tiktok.svg" alt='tiktok icon'/>
									</EventsLink>
								</li>
								<li className="list-inline-item youtube">
									<EventsLink
										data-bypass=""
										to="https://www.youtube.com/channel/UCb4-YAkq_0LRJcllvzoWjag"
										external="true">
										<img src="/assets/images/nav/youtube.svg" alt='youtube icon'/>
									</EventsLink>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

Footer.defaultProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
