import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventsLink from 'shared/general/EventsLink';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';
import { Redirect } from 'react-router';
import deps from 'dependencies';
const mapStateToProps = (state, props) => {
	return {
		settings: state['Config'].dataSettings,
		EventsWindow: state['WindowSize'].EventsWindow,
		userData: op.get(state['UserData'], 'userData', {}),
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		Router: state['Router'],
		site: state?.Config?.dataSettings?.site,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Nav.mount()),
	logout: () => dispatch(deps.actions.UserData.logoutUser()),
});

class NavContent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillUnmount() {
		logger.log('[NavContent] componentWillUnmount - props');
		document.body.classList.remove('js-nav');
	}

	showSubNav(which) {
		logger.log('[Nav] showSubNav ');
		MeasurementUtils.dispatchMeasurementCall('mainNavDisplay', {
			display: !this.state.menu_open,
			site: this.props.site,
			item: which
		});

		this.setState({
			menu_open: this.state.menu_open ? false : true,
		});
	}

	checkSubNav() {
		// When using keyboard navigation, check to see if "More" submenu is open
		// Close the submenu when tabbing out of the "More" option
		if (this.state.menu_open == true) {
			this.setState({
				menu_open: (this.state.menu_open = false),
			});
		}
	}

	closeDropDown(length, value) {
		//logger.log('[Nav] - closeDropDown event-length:%o,value:%o', length, value);
		if (value + 1 >= length) {
			this.showSubNav();
		}
	}

	onLogout() {
		this.props.logout();
		let isHomePage = this.props?.Router?.pathname == '/' || this.props?.Router?.params?.homePage ? true : false;

		if (!isHomePage) {
			logger.log('[NavContent] onLogout - only redirect if not homepage or content page: Router:%o', this.props.Router);
			window.location.replace(this.props.flowData.actions.general.logout);
		}

	}

	render() {
		logger.log('[NavContent] render - props: %o', this.props);
		let mainmenu = op.get(this.props, 'data.mainmenu', []);
		let currentPath = window.location.pathname;
		let selectClass = '';
		let moreClass = this.state.menu_open ? 'active' : '';
		let numericWindowSize = op.get(this.props, 'EventsWindow.numericWindowSize', 0);

		let menulogout = {};

		if (mainmenu.length > 0) {
			menulogout = mainmenu.find(menuitem => menuitem.section == 'logout');
			logger.log('[NavContent] render - menulogout: %o', menulogout);
		}

		return (
			<nav className={`mainmenu`} id="menu" role="navigation">
				<ul>
					{mainmenu.map((item, i) => {
						if (item.section === 'more') {
							let subMenuLength = item.subnav.length;
							if (numericWindowSize >= 3) {
								// render the more link for large screens
								return (
									<li
										id={item.section}
										key={i + item.section}
										className={`navigation_down_arrow ${moreClass}`}
										onClick={() => {
											this.showSubNav(item.title);
										}}>
										<div className="moreLink">{item.title}</div>
										<nav className={`submenu ${moreClass}`} role="navigation">
											<ul>
												{item.subnav.map((item, i) => {
													if (currentPath === item.link) {
														selectClass = 'selected';
													} else {
														selectClass = '';
													}

													return (
														<li
															key={i + item.section}
															className={selectClass + ' ' + item.section}
															onClick={() => {
																this.props.data.menuClickCallback;
															}}>
															<EventsLink to={item.link} style={selectClass}>
																{item.title}
															</EventsLink>
														</li>
													);
												})}
											</ul>
										</nav>
									</li>
								);
							} else {
								// render the subnav that goes under the hamburger menu
								return item.subnav.map((item, i) => {
									if (currentPath === item.link) {
										selectClass = 'selected';
									} else {
										selectClass = '';
									}

									return (
										<li
											key={i + item.section}
											className={selectClass + ' ' + item.section}
											onClick={() => {
												this.props.data.menuClickCallback;
											}}>
											<EventsLink to={item.link} style={selectClass}>
												{item.title}
											</EventsLink>
										</li>
									);
								});
							}
						} else {
							if (currentPath.indexOf('/' + item.section + '/') > -1) {
								if (currentPath.includes('pairings')) {
									selectClass = '';
								} else {
									// highlight the nav even when on sub pages
									selectClass = 'selected';
								}
							} else if (currentPath === item.link) {
								selectClass = 'selected';
							} else {
								selectClass = '';
							}

							//render main nav config links
							if (item.config) {
								return (
									<li
										id={item.section}
										key={i + item.section}
										className={selectClass}
										onClick={() => {
											this.props.data.menuClickCallback;
										}}>
										{this.renderConfig(item, selectClass)}
									</li>
								);
							} else {
								//render main nav
								let nav_list;

								if (item.section == 'logout' && this.props?.userData?.token) {
									if (typeof this.props.userData.email != 'undefined') {
										nav_list = (
											<a className={selectClass} onClick={() => this.onLogout()}>
												{item.title}
											</a>
										);
									} else {
										nav_list = '';
									}
								} else if (item.section !== 'logout') {
									nav_list = (
										<EventsLink to={item.link} style={selectClass}>
											{item.title}
										</EventsLink>
									);
								}
								return (
									<li
										id={item.section}
										key={i + item.section}
										className={selectClass}
										onClick={() => {
											this.props.data.menuClickCallback;
										}}
										onFocus={() => this.checkSubNav()}>
										{nav_list}
									</li>
								);
							}
						}
					})}
				</ul>
			</nav>
		);
	}
}

NavContent.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavContent);
