/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import AccountProfileContent from 'shared/page-content/AccountProfileContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: AccountProfile
 * -----------------------------------------------------------------------------
 */

 const AccountProfile = props => {
	logger.log('[AccountProfile] props:%o',props);
	return <AccountProfileContent />
}

export default AccountProfile;
