import MagicLink from './index';

export default [
	{
		path: ['/:lang(en_US)/:category(magiclink)/:action(request).html'],
		exact: true,
		component: MagicLink,
	},
	{
		path: ['/:site(patron)/:category(magiclink)/:action(request).html'],
		exact: true,
		component: MagicLink,
	},
];
