import React, { useEffect, useState, useRef } from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: CalendarButton
 * props:
 *  name: String
 *  description: String (optional)
 *  startDate: "YYYY/MM/DD"
 *  endDate: "YYYY/MM/DD"
 *  startTime: "HH:MM"
 *  endTime: "HH:MM"
 *  label: String
 *  location: String
 *  options: "'Apple', 'Google', 'Yahoo', 'iCal'" (etc.)
 * -----------------------------------------------------------------------------
 */

const CalendarButton = (props) => {
	let attributes = props.data;
	logger.log('[CalendarButton] - props:%o', props);

	const filterGoogleEvents = () => window?.["dataLayer"]?.["_googleData"]?.filter((item) => {
		//logger.log('[CalendarButton] - filterGoogleEvents item:%o', item);
		return item.eventCategory == 'Add-to-Calendar-Button'
	});
	const addToCalendarRef = useRef();

	const [isMounted, setMounted] = useState(false);
	const [googleEvents, setGoogleEvents] = useState(filterGoogleEvents());
	const [calendarSaved, setCalendarSaved] = useState(null);
	const [calendarInit, setCalendarInit] = useState(null);

	
	/**
	 * So, several things happeneing here.  
	 * Adding a mutation observer for the rendered calendar button because it adds the last event from user to 
	 * a dom element attribute.  Problem is it doesn't work well, because you cannot capture the selected calendar
	 * type.  That is only showing in the google tag manager analytics.  If we ever get rid of that, we are screwed.
	 */

	useEffect(() => {

		const targetNode = addToCalendarRef.current;
		const config = { attributes: true, childList: true, subtree: true};

		const callback = (mutationsList, observer) => {
			for (let mutation of mutationsList) {
				if (mutation.type === 'attributes') {
					logger.log('[CalendarButton] - modified attribute:%o, value:%o', mutation.attributeName, mutation.target.getAttribute(mutation.attributeName));
					logger.log('[CalendarButton] - modified attribute google data:%o', window?.["dataLayer"]?.["_googleData"]);
					setGoogleEvents(filterGoogleEvents());
				}
			}
		}

		const observer = new MutationObserver(callback);

		if (targetNode) {
			setMounted(true);
			setCalendarSaved(false);
			setCalendarInit(false);
			observer.observe(targetNode, config);
		}


		//logger.log('[CalendarButton] - useEffect test:%o, calendarSaved:%o', "[]", calendarSaved);

		return () => {
			setMounted(false);
			//window.dataLayer["push"] = undefined;

			if (targetNode) {
				observer.disconnect();
			}
		}

	}, []);

	useEffect(() => {
		//logger.log('[CalendarButton Measurement] - useEffect test, [googleEvents]:%o, calendarSaved:%o', googleEvents, calendarSaved);

		if (googleEvents.length !== 0) {
			let last = googleEvents[googleEvents.length - 1];

			if (last.event == 'success' && calendarSaved === false && googleEvents.length > 1) {
				let selected = googleEvents[googleEvents.length - 2];
				let type = selected['eventLabel']?.split('-');
				setCalendarSaved(true);
				MeasurementUtils.dispatchMeasurementCall('calendarButton', {
					...selected,
					selected:type.length > 0 ? type[type.length - 1] : undefined
				});
				MeasurementUtils.dispatchMeasurementCall('calendarButton', {
					...last,
					selected:type.length > 0 ? type[type.length - 1] : undefined
				});
			} else if (last.event == 'initialization' && calendarInit === false) {
				setCalendarInit(true);
				MeasurementUtils.dispatchMeasurementCall('calendarButton', {
					...last
				});
			}
			else if (last.event !== 'success' && last.event !== 'initialization') {
				MeasurementUtils.dispatchMeasurementCall('calendarButton', {
					...last
				});
				setCalendarSaved(false);
			}
		}
		
	}, [googleEvents])

	//logger.log('[CalendarButton] - window.dataLayer.push:%o, isMounted:%o, gtagLayer.push:%o', window?.dataLayer?.push, isMounted, window?.gtagLayer?.push);
	logger.log('[CalendarButton] - window.gtagLayer:%o', window?.["dataLayer"]?.["_googleData"]);
	//logger.log('[CalendarButton] - filterGoogleEvents:%o', filterGoogleEvents());
	logger.log('[CalendarButton] - googleEvents:%o', googleEvents);

	// pulling the css from the stylesheet for shadowroot
	const buttonColor = addToCalendarRef?.current ? window?.getComputedStyle(addToCalendarRef?.current)?.getPropertyValue('--buttonColor') : '';

	return (
		<div className="calendarButton-wrapper" ref={addToCalendarRef} style={attributes?.style}>
		
			{(window.dataLayer?.push && isMounted) && 
				<AddToCalendarButton
					name={attributes.name}
					identifier={attributes.identifier}
					description={attributes.description}
					startDate={attributes.startDate}
					endDate={attributes.endDate}
					startTime={attributes.startTime}
					endTime={attributes.endTime}
					label={attributes.label}
					location={attributes.location}
					options={attributes.options}
					timeZone="America/New_York"
					listStyle="dropdown"
					debug={false}
					styleLight={`--btn-background: #fff;
					--btn-border-radius: 0;
					--btn-active-shadow: none;
					--btn-hover-text: ${buttonColor};
					--list-border-radius: 0;
					--btn-text: ${buttonColor};
					--btn-border: ${buttonColor};
					--list-background: #fff;
					--list-hover-background: #f1f1f1;
					--btn-shadow: none;
					--btn-hover-shadow: none;`}
					hideBackground
					hideBranding
					hideCheckmark
					trigger="click"></AddToCalendarButton>
			}
		</div>
	)
};
export default CalendarButton;
