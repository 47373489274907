/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React from 'react';
 import CancelRegistrationContent from 'shared/page-content/CancelRegistrationContent';
 
 /**
  * -----------------------------------------------------------------------------
  * React Component: CancelRegistration
  * -----------------------------------------------------------------------------
  */
 
  const CancelRegistration = props => {
	 logger.log('[CancelRegistration] props:%o',props);
	 return <CancelRegistrationContent />
 }
 
 export default CancelRegistration;
 
