import ContentPage from './index';
import deps from 'dependencies';
export default [
	{
		path: ['/:lang(en_US)/info/:contentCategory(privacy|tickets|terms)/:action(index).html'],
		exact: true,
		component: ContentPage,
		load: params => deps.actions.Config.mount(params),
	},
	{
		path: ['/:lang(en_US)/info/:contentCategory(privacy|tickets|terms)/'],
		exact: true,
		component: ContentPage,
		load: params => deps.actions.Config.mount(params),
	},
	{
		path: ['/:lang(en_US)/:contentCategory(ticketsInfo|ticketsFAQ|ticketsPolicies)/:action(index).html'],
		exact: true,
		component: ContentPage,
		load: params => deps.actions.Config.mount(params),
	},
	{
		path: ['/:lang(en_US)/:contentCategory(ticketsInfo|ticketsFAQ|ticketsPolicies)/'],
		exact: true,
		component: ContentPage,
		load: params => deps.actions.Config.mount(params),
	},
	{
		path: ['/:lang(en_US)/info/:contentCategory(privacy|terms)/:action(agree).html'],
		exact: true,
		component: ContentPage,
		load: () => deps.actions.Config.loadConfig(),
		//load: params => deps.actions.Config.mount(params),
	},
	{
		path: ['/:lang(en_US)/info/:contentCategory(privacy|tickets|terms)/:action(index)/:display(print).html'],
		exact: true,
		component: ContentPage,
		load: params => deps.actions.Config.mount(params),
	},
	{
		path: ['/:lang(en_US)/info/:contentCategory(privacy|tickets|terms)/:display(print).html'],
		exact: true,
		component: ContentPage,
		load: params => deps.actions.Config.mount(params),
	},
];
