import React , { useState } from 'react';
import { useSelector } from 'react-redux';
import PaymentContent from 'shared/page-content/PaymentContent';


const Payment = props => {
	logger.log('[Payment] props:%o', props);

	const applicationConfig = useSelector(state => state['Config']?.application);
	const userData = useSelector(state => state['UserData']?.userData);
	const application = useSelector(state => state['UserData']?.userData?.application);
	const messageSettings = useSelector(state => state['Config']?.staticData?.cmsMessages?.data);
	const eventConfig = useSelector(state => state['Config']?.eventConfig?.data);

	const createInvoice = (isTourn) => {
		// invoice: [
		// 	{ DAY: 'Monday', 'TICKETS PURCHASED': 2, TOTALS: '150.00' },
		// 	{ DAY: 'Tuesday', 'TICKETS PURCHASED': 2, TOTALS: '150.00' },
		// 	{ DAY: 'Wednesday', 'TICKETS PURCHASED': 1, TOTALS: '75.00' },
		// ],
		let invoice = [];
		let days = Object.keys(applicationConfig.days);
		days.map(day => {
			if (
				application[`${day}Approved`] > 0 &&
				applicationConfig.days[day].isTourn == isTourn
			) {
				invoice.push({
					ROUND: `
						${messageSettings[`${day}_date_day_long`]}<br />
						${applicationConfig.days[day].day}<br/>
						At ${applicationConfig.days[day].location}<br /><br />
						$${eventConfig[`${day}Price`].toFixed(2)} each<br/>`,
					'TICKETS APPROVED': application[`${day}Approved`],
					TOTALS: `$${parseFloat(application[`${day}Owed`]).toFixed(2)}`,
				});
			}
		});

		logger.log('[Payment] createInvoice invoice:%o', invoice);
		return invoice;
	}
	
	return (
		<PaymentContent attributes={{
			createInvoice: (isTourn) => createInvoice(isTourn),
		}} />
	)
}
export default Payment;

