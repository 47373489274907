import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import throttle from 'lodash/throttle';
import deps from 'dependencies';
import EventsLink from 'shared/general/EventsLink';
import Sponsors from './SponsorList';
import MeasurementUtils from 'appdir/lib/analytics';
import NavContent from './NavContent';
import VisitButton from 'shared/general/VisitButton';

/**
 * -----------------------------------------------------------------------------
 * React Component: Nav
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Nav'],
		EventsWindow: state['WindowSize'].EventsWindow,
		stubPagesData: op.get(state['Config'], 'stubPagesData', null),
		mainnav: op.get(state['Config'], 'staticData.menu.data', []),
		configStatus: state['Config'].status,
		eventDates: op.get(state['Config'], 'dataSettings.eventDates', ''),
		site: state?.Config?.dataSettings?.site,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
});

class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//...this.props,
			menu_open: false,
			mobile_open: false,
		};

		this.toggleOpen = false;
		this.firstClick = true;
		this._isMounted = false;
	}
	componentDidMount() {
		logger.log('[Nav] componentDidMount - state:%o, props:%o', this.state, this.props);

		window.addEventListener('resize', throttle(this.onResize.bind(this), 10), false);
		this.props.loadConfig();
		this._isMounted = true;
	}

	componentWillUnmount() {
		document.body.classList.remove('js-nav');
		window.removeEventListener('resize', this.onResize.bind(this), false);
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[Nav] componentDidUpdate - state:%o, props:%o', this.state, this.props);
	}

	onResize() {
		// logger.log('[Nav] onResize - state:%o', this.state);
		this.autoClose();
	}

	autoClose() {
		if (this._isMounted) {
			if (this.props.EventsWindow.windowSize === 'desktop') {
				//auto close mobile menu with size change
				this.setState({
					mobile_open: false,
				});

				document.body.classList.remove('js-nav');
			}
		}
	}

	showMobile() {
		logger.log('[Nav] showMobile - state: %o, props: %o', this.state, this.props);
		this.toggleOpen = this.state.mobile_open;

		//first time clicking menu
		if (this.firstClick) {
			this.toggleOpen = true;
		} else {
			//toggle menu open/close
			this.toggleOpen = this.toggleOpen ? false : true;
		}

		this.setState({
			mobile_open: this.toggleOpen,
		});

		//add class for open/close menu
		if (this.toggleOpen) {
			document.body.classList.add('js-nav');
		} else {
			document.body.classList.remove('js-nav');
		}

		MeasurementUtils.dispatchMeasurementCall('mobileNavDisplay', {
			display: this.toggleOpen,
			site: this.props.site
		});

		//remove first time click
		this.firstClick = false;
	}

	hideMobile() {
		logger.log('[Nav] hideMobile - state: %o', this.state);
		this.toggleOpen = this.state.mobile_open;

		if (this.toggleOpen) {
			document.body.classList.remove('js-nav');

			this.setState({
				mobile_open: !this.toggleOpen,
			});
		}
		//document.body.classList.remove('js-nav');
	}

	render() {
		logger.log('[Nav] render - state:%o, props:%o', this.state, this.props);
		let mobileClass = this.state.mobile_open ? 'active' : '';
		let currentPath = window.location.pathname;

		logger.log('[Nav] render - currentPath:%o', currentPath);

		if (this.props.configStatus == 'loaded') {
			let mainmenu = this.props.mainnav;

			return (
				<section className="subheader">
					<div className={`hamburger-wrapper ${mobileClass}`}>
						<div
							className={`hamburger`}
							alt="Menu"
							onClick={() => {
								this.showMobile();
							}}>
							<div />
						</div>
					</div>
					<div className="flex-filler" />
					<NavContent
						data={{
							mainmenu,
							stubPages: this.props.stubPagesData,
							menuClickCallback: () => {
								this.hideMobile();
							},
						}}
					/>

					<div id="eventLogo">
						<VisitButton />
					</div>

					<Sponsors />

					{/* <div id="eventDate">
						<span>{this.props.eventDates}</span>
					</div> */}
				</section>
			);
		} else {
			return <section className="nav" />;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
