/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React from 'react';
 import AddressConfirmationContent from 'shared/page-content/AddressConfirmationContent';
 
 /**
  * -----------------------------------------------------------------------------
  * React Component: AccountProfile
  * -----------------------------------------------------------------------------
  */
 
  const AddressConfirmation = props => {
	 logger.log('[AddressConfirmation] props:%o',props);
	 return <AddressConfirmationContent />
 }
 
 export default AddressConfirmation;
 