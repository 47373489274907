/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import Review from './Review';
 import { connect } from 'react-redux';
 import deps from 'dependencies';
 import op from 'object-path';
 
 /**
  * -----------------------------------------------------------------------------
  * React Component: Review
  * -----------------------------------------------------------------------------
  */
 
 const mapStateToProps = (state, props) => {
	 return {
		 ...state.Review,
		 eventDays: op.get(state['Config'], 'application.days'),
		 EventsWindow: op.get(state['WindowSize'], 'EventsWindow', {}),
		 userData: op.get(state['UserData'], 'userData', {}),
		 eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
		 applicationConfig: op.get(state['Config'], 'application'),
		 validStatusIds: op.get(state['Config'], 'addressValidation.validStatusIds', {}),
		 messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
		 ...props,
	 };
 };
 
 const mapDispatchToProps = (dispatch, props) => ({
	 loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
 });
 
 export default connect(mapStateToProps, mapDispatchToProps)(Review);
 