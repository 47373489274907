/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React from 'react';
 import ApplicationContent from 'shared/page-content/ApplicationContent';
 import { useSelector } from 'react-redux';

 import * as yup from 'yup';

 /**
  * -----------------------------------------------------------------------------
  * React Component: Application
  * -----------------------------------------------------------------------------
  */
 
const Application = props => {
	 logger.log('[Application] props:%o',props);

	 const messageSettings = useSelector(state => state['Config']?.staticData?.cmsMessages?.data);
	 const eventDays = useSelector(state => state['Config']?.application?.days);

	 let ApplicationSchema = yup.object({
		// day1: yup.mixed().required('Round 1 value is required'),
		// day2: yup.mixed().required('Round 2 value is required'),
		// day3: yup.mixed().required('Final Round value is required'),

		day1: yup.mixed().test("day1", `${messageSettings?.['application.error_day_select']?.replace('{0}', eventDays['day1'].day)}`, (value) => {
			logger.log('[Application] day1 ApplicationSchema:%o, value:%o', value);
			let valid = false;
			if (value && value.label && value.value !== undefined && value.value !== '') {
				valid = true
			}
			logger.log('[Application] day1 valid:%o', valid);

			return valid}),
		day2: yup.mixed().test("day2", `${messageSettings?.['application.error_day_select']?.replace('{0}', eventDays['day2'].day)}`, (value) => {
			logger.log('[Application] day2 ApplicationSchema:%o, value:%o', value);
			let valid = false;
			if (value && value.label && value.value !== undefined && value.value !== '') {
				valid = true
			}
			logger.log('[Application] day2 valid:%o', valid);

			return valid}),
		day3: yup.mixed().test("day3", `${messageSettings?.['application.error_day_select']?.replace('{0}', eventDays['day3'].day)}`, (value) => {
			logger.log('[Application] day3 ApplicationSchema:%o, value:%o', value);
			let valid = false;
			if (value && value.label && value.value !== undefined && value.value !== '') {
				valid = true
			}
			logger.log('[Application] day2 valid:%o', valid);

			return valid}),
	});

	 return <ApplicationContent attributes={{ApplicationSchema}} />
 }
 
 export default Application;
 