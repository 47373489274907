/**
 * Generated by createManifest.js
 * DO NOT directly edit this file !!!!!!
 */

module.exports = {
    get: () => {
        return {
            allActions: {
                CookieWarning: require('components/general/CookieWarning/actions')
                    .default,
                WindowSize: require('components/general/WindowSize/actions')
                    .default,
                Plugable: require('dotix/components/Plugable/actions').default,
                Router: require('dotix/components/Router/actions').default,
                Config: require('shared/data/Config/actions').default,
                UserData: require('shared/data/UserData/actions').default,
                ContentInfo: require('shared/general/ContentInfo/actions')
                    .default,
                MastersImage: require('shared/general/MastersImage/actions')
                    .default,
                StubBoxCms: require('shared/general/StubBoxCms/actions')
                    .default,
            },
            allActionTypes: {
                CookieWarning: require('components/general/CookieWarning/actionTypes')
                    .default,
                WindowSize: require('components/general/WindowSize/actionTypes')
                    .default,
                Plugable: require('dotix/components/Plugable/actionTypes')
                    .default,
                Router: require('dotix/components/Router/actionTypes').default,
                Config: require('shared/data/Config/actionTypes').default,
                UserData: require('shared/data/UserData/actionTypes').default,
                ContentInfo: require('shared/general/ContentInfo/actionTypes')
                    .default,
                MastersImage: require('shared/general/MastersImage/actionTypes')
                    .default,
                StubBoxCms: require('shared/general/StubBoxCms/actionTypes')
                    .default,
            },
            allReducers: {
                CookieWarning: require('components/general/CookieWarning/reducers')
                    .default,
                WindowSize: require('components/general/WindowSize/reducers')
                    .default,
                Plugable: require('dotix/components/Plugable/reducers').default,
                Router: require('dotix/components/Router/reducers').default,
                Config: require('shared/data/Config/reducers').default,
                UserData: require('shared/data/UserData/reducers').default,
                ContentInfo: require('shared/general/ContentInfo/reducers')
                    .default,
                MastersImage: require('shared/general/MastersImage/reducers')
                    .default,
                StubBoxCms: require('shared/general/StubBoxCms/reducers')
                    .default,
            },
            allInitialStates: {
                CookieWarning: require('components/general/CookieWarning/state')
                    .default,
                Plugable: require('dotix/components/Plugable/state').default,
                Config: require('shared/data/Config/state').default,
                UserData: require('shared/data/UserData/state').default,
            },
            allRoutes: {
                AccountPin: require('components/pages/AccountPin/route')
                    .default,
                AccountProfile: require('components/pages/AccountProfile/route')
                    .default,
                AddressConfirmation: require('components/pages/AddressConfirmation/route')
                    .default,
                AddressVerification: require('components/pages/AddressVerification/route')
                    .default,
                Application: require('components/pages/Application/route')
                    .default,
                CancelRegistration: require('components/pages/CancelRegistration/route')
                    .default,
                ChangePassword: require('components/pages/ChangePassword/route')
                    .default,
                Confirmation: require('components/pages/Confirmation/route')
                    .default,
                ContentPage: require('components/pages/ContentPage/route')
                    .default,
                ForgotPassword: require('components/pages/ForgotPassword/route')
                    .default,
                Home: require('components/pages/Home/route').default,
                MagicLink: require('components/pages/MagicLink/route').default,
                Payment: require('components/pages/Payment/route').default,
                Review: require('components/pages/Review/route').default,
            },
            allServices: {
                Header: require('components/general/Header/services').default,
                Config: require('shared/data/Config/services').default,
                ContentInfo: require('shared/general/ContentInfo/services')
                    .default,
                MastersImage: require('shared/general/MastersImage/services')
                    .default,
                StubBoxCms: require('shared/general/StubBoxCms/services')
                    .default,
            },
            allMiddleware: {
                analytics: require('redux-addons/analytics/middleware').default,
                redux: require('dotix/redux/middleware').default,
            },
            allEnhancers: {
                redux: require('dotix/redux/enhancer').default,
            },
            allPlugins: {},
        };
    },
    contexts: {
        components:
            typeof window !== 'undefined' &&
            require.context('components', true, /.jsx?$/),
        dotix:
            typeof window !== 'undefined' &&
            require.context('dotix/components', true, /.jsx?$/),
        shared:
            typeof window !== 'undefined' &&
            require.context('shared', true, /.jsx?$/),
    },
    listContexts: () => {
        return;
    },
    list: () => {
        return {
            allActions: {
                type: 'actions',
                imports: [
                    'components/general/CookieWarning/actions',
                    'components/general/WindowSize/actions',
                    'dotix/components/Plugable/actions',
                    'dotix/components/Router/actions',
                    'shared/data/Config/actions',
                    'shared/data/UserData/actions',
                    'shared/general/ContentInfo/actions',
                    'shared/general/MastersImage/actions',
                    'shared/general/StubBoxCms/actions',
                ],
            },
            allActionTypes: {
                type: 'actionTypes',
                imports: [
                    'components/general/CookieWarning/actionTypes',
                    'components/general/WindowSize/actionTypes',
                    'dotix/components/Plugable/actionTypes',
                    'dotix/components/Router/actionTypes',
                    'shared/data/Config/actionTypes',
                    'shared/data/UserData/actionTypes',
                    'shared/general/ContentInfo/actionTypes',
                    'shared/general/MastersImage/actionTypes',
                    'shared/general/StubBoxCms/actionTypes',
                ],
            },
            allReducers: {
                type: 'reducers',
                imports: [
                    'components/general/CookieWarning/reducers',
                    'components/general/WindowSize/reducers',
                    'dotix/components/Plugable/reducers',
                    'dotix/components/Router/reducers',
                    'shared/data/Config/reducers',
                    'shared/data/UserData/reducers',
                    'shared/general/ContentInfo/reducers',
                    'shared/general/MastersImage/reducers',
                    'shared/general/StubBoxCms/reducers',
                ],
            },
            allInitialStates: {
                type: 'state',
                imports: [
                    'components/general/CookieWarning/state',
                    'dotix/components/Plugable/state',
                    'shared/data/Config/state',
                    'shared/data/UserData/state',
                ],
            },
            allRoutes: {
                type: 'route',
                imports: [
                    'components/pages/AccountPin/route',
                    'components/pages/AccountProfile/route',
                    'components/pages/AddressConfirmation/route',
                    'components/pages/AddressVerification/route',
                    'components/pages/Application/route',
                    'components/pages/CancelRegistration/route',
                    'components/pages/ChangePassword/route',
                    'components/pages/Confirmation/route',
                    'components/pages/ContentPage/route',
                    'components/pages/ForgotPassword/route',
                    'components/pages/Home/route',
                    'components/pages/MagicLink/route',
                    'components/pages/Payment/route',
                    'components/pages/Review/route',
                ],
            },
            allServices: {
                type: 'services',
                imports: [
                    'components/general/Header/services',
                    'shared/data/Config/services',
                    'shared/general/ContentInfo/services',
                    'shared/general/MastersImage/services',
                    'shared/general/StubBoxCms/services',
                ],
            },
            allMiddleware: {
                type: 'middleware',
                imports: [
                    'redux-addons/analytics/middleware',
                    'dotix/redux/middleware',
                ],
            },
            allEnhancers: {
                type: 'enhancer',
                imports: ['dotix/redux/enhancer'],
            },
            allPlugins: {
                type: 'plugin',
                imports: [],
            },
        };
    },
};
