/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React from 'react';
 import ChangePasswordContent from 'shared/page-content/ChangePasswordContent';
 
 /**
  * -----------------------------------------------------------------------------
  * React Component: ChangePassword
  * -----------------------------------------------------------------------------
  */
 
  const ChangePassword = props => {
	 logger.log('[ChangePassword] props:%o',props);
	 return <ChangePasswordContent />
 }
 
 export default ChangePassword;
 